import React from 'react'
import SkinProfileOptionCard from './SkinProfileOptionCard'

const options = ['Man', 'Woman', 'Non-binary', 'Self-Identify']
const SelectGender = ({ val, setVal }) => {
    return (
        <div>
            <p className='title'>
                What is your gender?
            </p>
            <p className='subtitle'>
                Select one
            </p>
            {options?.map(option => <SkinProfileOptionCard
                label={option}
                val={option}
                handlePress={() => setVal(option)}
                isSelected={val == option}
            />)}
        </div>
    )
}

export default SelectGender