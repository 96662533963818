import React from 'react'
import CustomInput from '../../components/core/CustomInput/CustomInput'

const EnterIgProfile = ({ placeholder, val, onChange }) => {
    return (
        <div>
            <p className='title'>Share your IG profile</p>
            <p className='subtitle'>Paste profile link</p>
            <CustomInput
                placeholder={placeholder}
                val={val}
                onChange={e => onChange(e.target.value)}
            />
        </div>
    )
}

export default EnterIgProfile