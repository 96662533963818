import React from 'react'
import CustomInput from '../../components/core/CustomInput/CustomInput'

const EnterDOB = ({ val, onChange }) => {
    return (
        <div>
            <p className='title'>What is your birthdate?</p>
            <p className='subtitle'>Enter date</p>
            <CustomInput
                placeholder={'17/08/1995'}
                val={val}
                onChange={e => onChange(e.target.value)}
            />
            <p style={{ alignSelf: 'flex-start', fontSize: 12, marginTop: 4, color: 'grey', textAlign: 'left', marginLeft: 16 }}>
                DD/MM/YYYY
            </p>
        </div>
    )
}

export default EnterDOB