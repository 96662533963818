import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import './SignInModal.css'
import Logo from '../../../assets/images/svgs/logo.svg'
import OTPInput from 'react-otp-input';
import OtpInput from './OtpInput';

const SignInModal = ({ show, close, signIn, onSubmit, onChange, setSignIn, otpInput, setOtpValue, onSubmitOtp }) => {
    return (
        <>
            <Modal
                show={show}
                onHide={close}
                aria-labelledby="contained-modal-title-vcenter br4"
                centered
            >
                <Modal.Body className='modal-container'>
                    <img src={Logo} className='img' />
                    {!otpInput ? <>
                        <p className='title'>Login to your profile</p>
                        <div className='input-container'>
                            <p className='country-code'>+91</p>
                            <input
                                className='mobile-input'
                                maxLength={10}
                                placeholder='Phone number'
                                onChange={e => onChange(e.target.value)}
                            />
                        </div>
                    </>
                        : <>
                            <div>
                                <OtpInput
                                    // value={otpValue}
                                    // onChange={setOtpValue}
                                    length={4}
                                    onComplete={setOtpValue}
                                    otpType="number"
                                    disabled={false}
                                    inputStyles={{ backgroundColor: '#2B3274', borderColor: '#ffffff66', color: '#fff', outline: 'none' }}
                                />
                            </div>
                        </>}
                    <Button
                        className='signin-btn'
                        onClick={otpInput ? onSubmitOtp : onSubmit}
                        style={{ width: otpInput ? '90%' : '100%' }}
                    >
                        {signIn ? 'Sign in' : 'Sign up'}
                    </Button>
                    {signIn && <p
                        className='no-account'
                        onClick={setSignIn}
                    >
                        Dont have account?<span style={{ color: '#4254EA', opacity: 1 }}> Create Account</span>
                        {/* {signIn && 'Dont have account? Create Account'} */}
                    </p>}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SignInModal