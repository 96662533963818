import React from 'react'
import SkinProfileOptionCard from './SkinProfileOptionCard'

const options = ['Oily', 'Combination', 'Dry', 'I don’t know']
const SelectSkinType = ({ val, setVal }) => {
    return (
        <div>
            <p className='title'>
                What is your skin type?
            </p>
            <p className='subtitle'>
                Select one
            </p>
            {options?.map(option => <SkinProfileOptionCard
                label={option}
                val={option}
                handlePress={() => setVal(option)}
                isSelected={val == option}
            />)}
        </div>
    )
}

export default SelectSkinType