import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import './Dashboard.css'
import UBCoin from '../../assets/images/svgs/ubcoin.svg'
import TransferPng from '../../assets/images/svgs/rupeesCircle.svg'
import UploadImageModal from '../../components/Challenge/UploadImage/UploadImageModal';
import CopyLink from '../../components/core/CopyLinkModal.js/CopyLink';
import HeaderTab from './HeaderTab';
import LifeStyleChallenge from '../../components/Challenge/LifeStyleChallenge/LifeStyleChallenge';
import { useNavigate } from 'react-router';
import Header from '../../components/core/Header/Header';
import BottomTabs from '../../components/core/BottomTabs/BottomTabs';
import { getData } from '../../utils/axiosInstance';
import BeautyProfileChallenge from '../../components/Challenge/BeautyProfileChallenge/BeautyProfileChallenge';
import ChallengeCardV2 from './ChallengeCardV2';
import RecommendationChallengeModalV2 from '../../components/Challenge/RecommendationChallengeModal/RecommendationChallengeModalV2';
import moment from 'moment';
import PricingPopup from '../../components/core/PricingPopup/PricingPopup';

const DashboardV2 = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showRec, setShowRec] = useState(false);
    const [showLifeStyle, setShowLifeStyle] = useState(false);
    const [showBeauty, setShowBeauty] = useState(false);
    const [showCopyLink, setShowCopyLink] = useState(false);
    const [token, setToken] = useState(null);
    const [selcted, setSelected] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const [coinsEarned, setCoinsEarned] = useState({});
    const [user, setUser] = useState(null);
    const [challenges, setChallenges] = useState([]);
    const [allChallenges, setAllChallenges] = useState([]);
    const [category, setCategory] = useState('');
    const [completedChallenges, setCompletedChallenges] = useState([]);
    const [pendingChallenges, setPendingChallenges] = useState([]);
    const [stats, setStats] = useState({})
    const [reviewChallenge, setReviewChallenge] = useState([]);
    const [thisWeekChallenges, setThisWeekChallenges] = useState([]);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [showPaymentPopup, setShowPaymentPopup] = useState(false);
    const [challgesSubmitted, setChallengesSubmitted] = useState([]);
    const [paymentStatus, setPamentStatus] = useState(false);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
            navigate('/signup')
        } else setUser(userData)
    }, [])

    const fetchData = async () => {
        const response = await getData(`influencer/stats/${user?.userID}`)
        setStats(response)
        const coinsRes = await getData(`coin/user/${user?.userID}`)
        setCoinsEarned(coinsRes)
    }

    useEffect(() => {
        if (user?.userID) fetchData();
    }, [user])

    const sortActiveChallenges = (activeArray) => {
        const lifstyleArr = activeArray.filter(x => x?.category == 'lifestyle')
        const beautyArr = activeArray.filter(x => x?.category == 'beauty')
        const noMakeUpArray = activeArray.filter(x => x?.name == 'Share a Selfie (No makeup)')
        const generalArray = activeArray.filter(x => x?.category == 'general' && x?.name != 'Share a Selfie (No makeup)')
        // const skincareColectionArr = activeArray.filter(x => x?.category == 'skincare' && x?.type == "image_upload")
        // const skincareRecArr = activeArray.filter(x => x?.category == 'skincare' && x?.type == "recommendation")
        // const haircareRecArr = activeArray.filter(x => x?.category == 'haircare')
        // const makeupRecArr = activeArray.filter(x => x?.category == 'makeup')
        const allActive = [...beautyArr, ...lifstyleArr, ...noMakeUpArray, ...generalArray]
        return allActive

    }

    const fetchChallenges = async () => {
        const res = await getData(`influencer/challenges/all`)
        const sortedAllChallenges = sortActiveChallenges(res?.items)
        setAllChallenges(sortedAllChallenges)
        const generalTypeArray = res?.items.filter(x => x?.type == 'general')
        const rev = [...generalTypeArray]
        setReviewChallenge(rev)
        if (user) {
            const response = await getData(`challenge/user/${user?.userID}/all`)
            const ids = response?.items?.filter(y => y?.status != 3 && y?.category != 'lifestyle')?.map(x => x?.cd_id)
            const lifestyleChallengeHasData = response?.items?.filter(y => y?.category == 'lifestyle')
            if (lifestyleChallengeHasData?.[0]?.age && lifestyleChallengeHasData?.[0]?.gender && lifestyleChallengeHasData?.[0]?.city) {
                ids.push(lifestyleChallengeHasData?.[0]?.cd_id)
            }
            setChallengesSubmitted(response?.items)
            const active = sortedAllChallenges?.filter(x => !ids?.includes(x?.cd_id))
            const completed = response?.items?.filter(x => x?.status == 4);
            const completedIds = completed?.map(x => x?.cd_id);
            const pending = response?.items?.filter(x => x?.status > 1 && x.status < 4 && !completedIds.includes(x?.cd_id))
            const startOfWeek = moment().startOf('week');
            const endOfWeek = moment().endOf('week');
            const thisWeek = response?.items?.filter(item => moment(item?.inserted_at)?.isSameOrAfter(startOfWeek) && moment(item?.inserted_at)?.isBefore(endOfWeek) && item?.type == 'general')
            setThisWeekChallenges(thisWeek);
            setCompletedChallenges(completed);
            setPendingChallenges(pending);
            setChallenges(active)
        }
    }

    const getSubscriptionDetails = async () => {
        if (user) {
            await getData(`access/subscription/status/${user?.userID}`)
                .then((res) => setSubscriptionStatus(res))
                .catch((err) => console.log('err', err))
        }
    }

    useEffect(() => {
        fetchChallenges();
        getSubscriptionDetails()
    }, [user])

    const handleClickChallenge = (item) => {
        if (paymentStatus) {
            setShowPaymentPopup(true)
        } else {
            setSelected(item);
            if (item?.type == 'lifestyle') {
                setShowLifeStyle(true)
            }
            if (item?.type == 'beauty') {
                setShowBeauty(true)
            }
            if (item?.type == 'image_upload') {
                setShow(true)
            } else if (item?.type == 'recommendation' || item?.type == 'general') {
                setShowRec(true)
            }
        }
    }

    const handleOnComplete = async () => {
        const res = await getData(`influencer/challenges/all`)
        const sortedAllChallenges = sortActiveChallenges(res?.items)
        setAllChallenges(sortedAllChallenges)
        const generalTypeArray = res?.items.filter(x => x?.type == 'general')
        const rev = [...generalTypeArray]
        setReviewChallenge(rev)
        if (user) {
            const response = await getData(`challenge/user/${user?.userID}/all`)
            const completed = response?.items?.filter(x => x?.status == 4)
            const pending = response?.items?.filter(x => x?.status > 1 && x.status < 4)
            setChallengesSubmitted(response?.items)
            setCompletedChallenges(completed);
            setPendingChallenges(pending);
            const ids = response?.items?.filter(y => y?.status != 3 && y?.category != 'lifestyle')?.map(x => x?.cd_id)
            const lifestyleChallengeHasData = response?.items?.filter(y => y?.category == 'lifestyle')
            if (lifestyleChallengeHasData?.[0]?.age && lifestyleChallengeHasData?.[0]?.gender && lifestyleChallengeHasData?.[0]?.city) {
                ids.push(lifestyleChallengeHasData?.[0]?.cd_id)
            }
            const active = sortedAllChallenges?.filter(x => !ids?.includes(x?.cd_id))
            const startOfWeek = moment().startOf('week');
            const endOfWeek = moment().endOf('week');
            const thisWeek = response?.items?.filter(item => moment(item?.inserted_at)?.isSameOrAfter(startOfWeek) && moment(item?.inserted_at)?.isBefore(endOfWeek) && item?.type == 'general')
            setThisWeekChallenges(thisWeek);
            sortActiveChallenges(active)
            setChallenges(active)
            setShow(false);
            setShowRec(false);
        }
    }

    const allcategories = allChallenges?.map(x => x?.category)
    const distinctCategories = []
    for (let index = 0; index < allcategories.length; index++) {
        const element = allcategories[index];
        if (element && !distinctCategories.includes(element) && element != 'lifestyle' && element != 'beauty') {
            distinctCategories.push(element)
        }
    }

    const filterQuery = (x) => {
        return category ? category == 'general' ? x.category == category || x.category == 'lifestyle' || x?.category == 'beauty' : x.category == category : x
    }


    useEffect(() => {
        if (subscriptionStatus) {
            if (challgesSubmitted?.length > 7 && !subscriptionStatus?.hasAccess) {
                setShowPaymentPopup(true)
                setPamentStatus(true)
                return
            }
            const compareTo = moment(subscriptionStatus?.subsription?.inserted_at)
            const difference = moment().diff(compareTo, 'days')
            if (challgesSubmitted > 7 && difference >= 30) {
                setShowPaymentPopup(true)
                setPamentStatus(true)
            }
        }
    }, [challgesSubmitted, subscriptionStatus])

    // console.log('Thsis....', challenges);
    return (
        <Container>
            <Row
                className="justify-content-center align-content-start p-2"
                style={{ height: '100vh', marginTop: 80 }}
            >
                <Col md={12} lg={12} sm={12} xs={12} className='wrap-header'>
                    <Header />
                </Col>
                {/* <Col md={4} sm={12} xs={12}> */}
                <div className='banner'>
                    <Row className='p0 m0'>
                        <Col md={6} sm={6} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', paddingLeft: 16 }}>
                            {user?.userName && <div style={{ display: 'flex' }} className='mb-1'>
                                <div className='avatar'>
                                    <p className='user-name m-0'>{
                                        user?.userName?.[0] == "{"
                                            ? user?.userName?.[1]
                                            : user?.userName?.[0]}
                                    </p>
                                </div>
                                <p className='user-name mb-0'>
                                    {user?.userName?.[0] == "{" ? user?.userName?.slice(1, -1) : user?.userName}
                                </p>
                            </div>}
                            <p className='earned'>Total E-rupee Earned</p>
                            <p className='amt' style={{ fontWeight: 600 }}>₹{coinsEarned?.balance}</p>
                            <div className='upload-btn' onClick={() => navigate('/claim')}>
                                <img src={TransferPng} style={{ height: 20, width: 20 }} />
                                <p>Claim Reward</p>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <img
                                src={UBCoin}
                                style={{
                                    height: 112,
                                    width: 112,
                                    padding: 0
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row className='mt-4 mb-4' style={{ borderBottom: '0.5px solid #ffffff66', marginLeft: 0, marginRight: 0 }}>
                        <HeaderTab
                            label={'Tasks'}
                            active={activeTab == 0}
                            onClick={() => {
                                setActiveTab(0)
                                setCategory('')
                            }}
                        />
                        <HeaderTab
                            label={'Review'}
                            active={activeTab == 1}
                            onClick={() => {
                                setActiveTab(1)
                                setCategory('')
                            }}
                        />
                        <HeaderTab
                            label={'Completed'}
                            active={activeTab == 2}
                            onClick={() => {
                                setActiveTab(2)
                                setCategory('')
                            }}
                        />
                    </Row>
                </div>
                {activeTab == 0 ?
                    <>
                        {challenges
                            ?.filter(x => filterQuery(x))
                            ?.map(item => <ChallengeCardV2
                                item={item}
                                onClick={handleClickChallenge}
                                status={item?.status}
                            />
                            )}
                        {challenges?.length == 0
                            && <>
                                {/* {thisWeekChallenges?.length >= 2
                                    ? <p style={{ fontSize: 14, color: '#fff', textAlign: 'center', padding: 24 }}>
                                        There are no challenges     left currently, when your submitted challenges are approved we’ll update new challenges for you!
                                    </p>
                                    : <> */}
                                {reviewChallenge
                                    ?.map(item => <ChallengeCardV2
                                        item={item}
                                        onClick={handleClickChallenge}
                                        status={item?.status}
                                    />
                                    )}
                                {/* </>
                                }  */}
                            </>}
                    </>
                    : activeTab == 1 ? <>
                        {pendingChallenges
                            ?.filter(x => filterQuery(x))
                            ?.map((item, index) =>
                                <>
                                    <ChallengeCardV2
                                        item={item}
                                        onClick={handleClickChallenge}
                                        status={item?.status}
                                        pending={true}
                                        // pendingChallenges={pendingChallenges?.filter(x => category ? x.category == category : x)}
                                        pendingChallenges={pendingChallenges}
                                    />
                                </>)}
                    </>
                        : activeTab == 2 ? <>
                            {completedChallenges
                                ?.filter(x => filterQuery(x))
                                ?.map(item => <ChallengeCardV2
                                    item={item}
                                    onClick={() => { }}
                                    status={item?.status}
                                />)}
                        </>
                            : <></>}
                {/* </Col> */}
                <div style={{ height: 300 }} />
                <BottomTabs />
            </Row>
            <UploadImageModal
                show={show}
                close={() => setShow(false)}
                selected={selcted}
                onComplete={handleOnComplete}
            />
            <RecommendationChallengeModalV2
                show={showRec}
                close={() => {
                    setSelected({})
                    setShowRec(false)
                }}
                selected={selcted}
                onComplete={handleOnComplete}
            />

            <LifeStyleChallenge
                show={showLifeStyle}
                close={() => setShowLifeStyle(false)}
                selected={selcted}
                onComplete={handleOnComplete}
            />

            <BeautyProfileChallenge
                show={showBeauty}
                close={() => setShowBeauty(false)}
                selected={selcted}
                onComplete={handleOnComplete}
            />
            <CopyLink
                show={showCopyLink}
                close={() => setShowCopyLink(false)}
                user={user}
                onComplete={handleOnComplete}
            />
            <PricingPopup
                show={showPaymentPopup}
                close={() => setShowPaymentPopup(false)}
                onComplete={() => {
                    getSubscriptionDetails()
                    setShowPaymentPopup(false)
                }}
            />
        </Container >
    )
}

export default DashboardV2