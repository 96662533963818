import React, { useState, useRef } from 'react';
import '../CustomInput/CustomInput.css'
const OtpInput = ({ length, onComplete }) => {
    const [otp, setOtp] = useState(Array(length).fill(''));
    const inputRefs = Array(length).fill(null);

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < length - 1) {
                inputRefs[index + 1].focus();
            }
            if (newOtp.join('').length === length) {
                onComplete(newOtp.join(''));
            }
        }
    };

    const handleInputKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
            index > 0 && inputRefs[index - 1].focus();
        }
    };

    return (
        <div style={{ marginBottom: 24, marginTop: 24 }}>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className='otp-input'
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleInputKeyDown(e, index)}
                    ref={(input) => (inputRefs[index] = input)}
                />
            ))}
        </div>
    );
};

export default OtpInput;