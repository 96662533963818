import React from 'react'
import SkinProfileOptionCard from './SkinProfileOptionCard'

const options = ['Brighten skin', 'Dehydrated skin', 'Decrease dryness', 'Glowing skin', 'Improve texture', 'Increase hydration', 'Maintain healthy skin', 'Minimize pores', 'Reduce acne/pimples/breakouts']

const SelectConcern = ({ val, setVal }) => {
    const selected = (option) => {
        return val?.includes(option)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div className='scrolling-comp'>
                <p className='title'>
                    What are your skin concerns?
                </p>
                <p className='subtitle'>
                    Select three
                </p>
                {options?.map(option => <SkinProfileOptionCard
                    label={option}

                    val={option}
                    handlePress={() => setVal(option)}
                    isSelected={selected(option)}
                />)}
            </div>
        </div>
    )
}

export default SelectConcern