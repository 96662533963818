// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-title {
    color: #000;
    text-align: center;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 20px !important;
}

.get-started-btn {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 12px;
    background: #4254EA;
    max-width: 180px;
    align-self: center;
    cursor: pointer;
}

.get-started-btn p {
    color: #FFF;
    text-align: center;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 150%;
}`, "",{"version":3,"sources":["webpack://./src/screens/SplashScreen/SplashScreen.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".splash-title {\n    color: #000;\n    text-align: center;\n    font-family: Urbanist;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 150%;\n    margin-top: 20px !important;\n}\n\n.get-started-btn {\n    display: flex;\n    padding: 8px 24px;\n    justify-content: center;\n    align-items: center;\n    gap: 5px;\n    border-radius: 12px;\n    background: #4254EA;\n    max-width: 180px;\n    align-self: center;\n    cursor: pointer;\n}\n\n.get-started-btn p {\n    color: #FFF;\n    text-align: center;\n    font-family: Urbanist;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    margin-bottom: 0;\n    line-height: 150%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
