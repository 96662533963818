import React, { useEffect, useState } from 'react'
import './PricingPopup.css'
import { Modal } from 'react-bootstrap'
import Close from '../../../assets/images/svgs/closeBlack.svg'
import Premium from '../../../assets/images/pngs/premium4.png'
import PremiumBg from '../../../assets/images/pngs/bgPremium.png'
import rocket from '../../../assets/images/svgs/rocket.svg'
import hero from '../../../assets/images/svgs/hero.svg'
import users from '../../../assets/images/svgs/users.svg'
import { postData } from '../../../utils/axiosInstance'
import { useNavigate } from 'react-router'

const PricingPopup = ({ show, close, onComplete }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState({});
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
            navigate('/signup')
        } else setUser(userData)
    }, [])


    const initPayment = (data) => {
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: data.amount,
            currency: data.currency,
            description: "Test Transaction",
            order_id: data.id,
            userId: user?.userID,
            handler: async (response) => {
                try {
                    const reqData = {
                        ...response,
                        ...data,
                        user_id: user?.userID,
                    }
                    await postData('payments/verify-payment/', reqData)
                        .then((res) => onComplete())
                        .catch((err) => console.log(err))
                } catch (error) {
                    console.log(error);
                }
            },
            theme: {
                color: "#283072",
            },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    const handleCreateOrder = async () => {
        const data = { amount: 99 }
        setLoading(true)
        await postData(`payments/create-order`, data)
            .then((res) => {
                console.log(res?.data)
                initPayment(res?.data);
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })

    }

    return (
        <Modal
            show={show}
            onHide={close}
            aria-labelledby="contained-modal-title-vcenter br4"
            centered
        >
            <div className='p-4'>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10, marginBottom: 10, borderBottom: '1px solid #E4E4E7' }}>
                    <p className='heading-txt'></p>
                    <div className='close-btn'>
                        <img src={Close} style={{ cursor: 'pointer' }} onClick={close} />
                    </div>
                </div>
                <div style={{ display: 'flex', padding: 10, borderRadius: 24 }}>
                    <img src={Premium} style={{ width: '100%', cursor: 'pointer' }} onClick={() => loading ? console.log('loading') : handleCreateOrder()}/>
                    {/* <div style={{ position: 'absolute', margin: 16, width: '80%' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ backgroundColor: "#4031B3", padding: '6px 24px', display: 'flex', alignSelf: 'flex-end', borderRadius: 100 }}>
                                <p style={{ fontSize: 12, color: '#FCF36B', marginBottom: 0 }}>MOST POPULAR</p>
                            </div>
                        </div>
                        <p style={{ fontSize: 24, color: '#fff', fontWeight: 700 }}>
                            <span style={{ textDecorationLine: 'line-through' }}>{'₹ 120'}</span> {' > 99'}
                        </p>
                        <p style={{ fontSize: 14, color: '#fff', fontWeight: '500' }}>
                            For access to unlimited tasks and earnings
                        </p>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <img src={rocket} />
                            <p style={{ fontSize: 14, color: '#fff', fontWeight: '500', marginLeft: 10, marginTop: 12 }}>Earn ₹300+ every month</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <img src={hero} />
                            <p style={{ fontSize: 14, color: '#fff', fontWeight: '500', marginLeft: 10, marginTop: 12 }}>Unlimited product recommendations</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <img src={users} />
                            <p style={{ fontSize: 14, color: '#fff', fontWeight: '500', marginLeft: 10, marginTop: 12 }}>Members’ deals & exclusive discounts</p>
                        </div>
                        <div
                            style={{ backgroundColor: '#FCF36B', borderRadius: 12, marginTop: 12, padding: 8, width: '100%', cursor: 'pointer' }}
                            onClick={() => loading ? console.log('loading') : handleCreateOrder()}
                        >
                            <p style={{ fontSize: 16, color: '3F4E99', fontWeight: '600', textAlign: 'center', marginBottom: 0 }}>
                                Choose this plan
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </Modal>
    )
}

export default PricingPopup