export const productCategories = [
    { label: "mascara", val: "mascara", category: "makeup" },
    { label: "treatment", val: "treatment", category: "skincare" },
    { label: "body lotion/moisturiser", val: "body lotion/moisturiser", category: "skincare" },
    { label: "primer", val: "primer", category: "makeup" },
    { label: "lipstick/lipgloss", val: "lipstick/lipgloss", category: "makeup" },
    { label: "eyeliner/kajal", val: "eyeliner/kajal", category: "makeup" },
    { label: "hair mask", val: "hair mask", category: "haircare" },
    { label: "cleanser", val: "cleanser", category: "skincare" },
    { label: "foundation", val: "foundation", category: "makeup" },
    { label: "lip care", val: "lip care", category: "skincare" },
    { label: "contour/highlighter", val: "contour/highlighter", category: "makeup" },
    { label: "face/setting powder", val: "face/setting powder", category: "makeup" },
    { label: "blush/bronze", val: "blush/bronze", category: "makeup" },
    { label: "conceler", val: "conceler", category: "makeup" },
    { label: "hair oil", val: "hair oil", category: "haircare" },
    { label: "hair serum", val: "hair serum", category: "haircare" },
    { label: "sunscreen", val: "sunscreen", category: "skincare" },
    { label: "body wash/body soap", val: "body wash/body soap", category: "skincare" },
    { label: "eyecare", val: "eyecare", category: "skincare" },
    { label: "shampoo", val: "shampoo", category: "haircare" },
    { label: "moisturiser", val: "moisturiser", category: "skincare" }
]

export const mapCategories = {
    "mascara": "makeup",
    "treatment": "skincare",
    "body lotion/moisturiser": "skincare",
    "primer": "makeup",
    "lipstick/lipgloss": "makeup",
    "eyeliner/kajal": "makeup",
    "hair mask": "haircare",
    "cleanser": "skincare",
    "foundation": "makeup",
    "lip care": "skincare",
    "contour/highlighter": "makeup",
    "face/setting powder": "makeup",
    "blush/bronze": "makeup",
    "conceler": "makeup",
    "hair oil": "haircare",
    "hair serum": "haircare",
    "sunscreen": "skincare",
    "body wash/body soap": "skincare",
    "eyecare": "skincare",
    "shampoo": "haircare",
    "moisturiser": "skincare"
}