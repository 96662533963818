// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
    height: 40px;
    padding: 13px 5px 13px 15px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #D4D4D8;
    background-color: #F7F7F7;
    color: #71717A;
    /* margin-top: 16px; */
}

.input:focus {
    outline: none;
    border-color: #D4D4D8;
}

.input textarea:focus-visible {
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/core/CustomInput/CustomInput.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,2BAA2B;IAC3B,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".input {\n    height: 40px;\n    padding: 13px 5px 13px 15px;\n    width: 100%;\n    border-radius: 12px;\n    border: 1px solid #D4D4D8;\n    background-color: #F7F7F7;\n    color: #71717A;\n    /* margin-top: 16px; */\n}\n\n.input:focus {\n    outline: none;\n    border-color: #D4D4D8;\n}\n\n.input textarea:focus-visible {\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
