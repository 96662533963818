import React from 'react'
import { Col, Image, Row, Button } from 'react-bootstrap';
// import imageSrc from '../assets/brand-logo.png'

const LandingPage = () => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    console.log('isnisminfinnfvnjvnfnfkj');
    return (
        <div style={{ flex: 1, width: '100%', backgroundColor: '#2B3274', height: '100%' }}>
            {/* <img src={imageSrc} alt="Image" style={{ height: 76, width: 86 }} /> */}
            <Col md={4} sm={12} xs={12}>
                {/* <div className='d-flex justify-content-center'> */}
                <form style={{ padding: 30, paddingBottom: 16 }}>
                    <p style={{ fontSize: 24, color: '#000', fontWeight: '600' }}>
                        Fill the form to apply for
                        UB Influencer Program:
                    </p>
                    <div className="form-group">
                        <label htmlFor="name">Full Name</label>
                        <input type="text" id="name" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Instagram ID</label>
                        <input type="email" id="email" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Whatsapp</label>
                        <input type="number" id="email" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
                        <p style={{ color: '#4254EA', fontSize: 16 }}>Send OTP</p>
                    </div>
                </form>
                <div style={{ padding: 30, paddingTop: 0 }}>
                    <p style={{ color: '#000', fontSize: 16 }}>Number of followers </p>
                    <Row>
                        <Col md={1} sm={1} xs={1}>
                            <div style={{ width: 20, height: 20, borderRadius: 20, border: '2px solid #6750A4', padding: 0, display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: '#6750A4', padding: 0, display: 'flex', alignSelf: 'center' }} />
                            </div>
                        </Col>
                        <Col md={11} sm={11} xs={11}>
                            <p style={{ fontSize: 16, color: '#1C1B1F', display: 'flex', alignSelf: 'center' }}>0-500</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1} sm={1} xs={1}>
                            <div style={{ width: 20, height: 20, borderRadius: 20, border: '2px solid #6750A4', padding: 0, display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: '#6750A4', padding: 0, display: 'flex', alignSelf: 'center' }} />
                            </div>
                        </Col>
                        <Col md={11} sm={11} xs={11}>
                            <p style={{ fontSize: 16, color: '#1C1B1F', display: 'flex', alignSelf: 'center' }}>500-1000</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1} sm={1} xs={1}>
                            <div style={{ width: 20, height: 20, borderRadius: 20, border: '2px solid #6750A4', padding: 0, display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: '#6750A4', padding: 0, display: 'flex', alignSelf: 'center' }} />
                            </div>
                        </Col>
                        <Col md={11} sm={11} xs={11}>
                            <p style={{ fontSize: 16, color: '#1C1B1F', display: 'flex', alignSelf: 'center' }}>More than 1000</p>
                        </Col>
                    </Row>
                </div>
                <div style={{ padding: '0 30px 0 30px' }}>
                    <Button variant="primary" size="lg" style={{ width: '100%', borderRadius: 36, fontSize: 14, padding: '12px 0 12px 0' }}>
                        Submit
                    </Button>
                </div>
                {/* </div> */}
            </Col>
            <div style={{ padding: 30, marginTop: 16, alignSelf: 'center' }}>
                <p style={{ fontSize: 12, color: '#9B9B9B', textAlign: 'center' }}>
                    © 2023 Unsweetened Beauty. All Rights Reserved.
                </p>
            </div>
        </div>
    )
}

export default LandingPage