// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading-txt {
    color: var(--black, #000);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 0;
    /* 30px */
}

.bullet-point {
    color: var(--black, #000);
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: 10px !important;
    /* 24px */
}`, "",{"version":3,"sources":["webpack://./src/components/core/InfoPopup/InfoPopup.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,4BAA4B;IAC5B,SAAS;AACb","sourcesContent":[".heading-txt {\n    color: var(--black, #000);\n    font-family: Urbanist;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 150%;\n    margin-bottom: 0;\n    /* 30px */\n}\n\n.bullet-point {\n    color: var(--black, #000);\n    font-family: Urbanist;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 150%;\n    margin-left: 10px !important;\n    /* 24px */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
