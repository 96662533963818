// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-text {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
}

.subtitle-text {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    opacity: 0.6;
}

.verify-btn {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    height: 51px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 37px;
    background: #4254EA;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.verify-btn p {
    color: #FFF;
    text-align: center;
    align-self: center;
    font-size: 16px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}`, "",{"version":3,"sources":["webpack://./src/screens/ThanksForSubmitting/ThanksForSubmitting.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,mBAAmB;IACnB,mBAAmB;IACnB,+CAA+C;IAC/C,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;AACzB","sourcesContent":[".title-text {\n    font-size: 24px;\n    color: #fff;\n    font-weight: 600;\n}\n\n.subtitle-text {\n    font-size: 16px;\n    color: #fff;\n    font-weight: 400;\n    opacity: 0.6;\n}\n\n.verify-btn {\n    display: flex;\n    margin-top: 24px;\n    margin-bottom: 24px;\n    height: 51px;\n    padding: 0px 12px;\n    justify-content: center;\n    align-items: center;\n    gap: 3px;\n    border-radius: 37px;\n    background: #4254EA;\n    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);\n    cursor: pointer;\n}\n\n.verify-btn p {\n    color: #FFF;\n    text-align: center;\n    align-self: center;\n    font-size: 16px;\n    margin-top: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px;\n    letter-spacing: 0.1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
