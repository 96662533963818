import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './SignUpForm.css'
import SignInModal from '../../components/core/SignInModal/SignInModal';
import axios from 'axios';
import EnterCity from './EnterCity';
import EnterName from './EnterName';
import EnterIgProfile from './EnterIgProfile';
import SelectFollowers from './SelectFollowers';
import SelectGender from './SelectGender';
import EnterDOB from './EnterDOB';
import SelectSkinType from './SelectSkinType';
import SelectConcern from './SelectConcern';
import SelectAgeGroup from './SelectAgeGroup';
import Next from '../../assets/images/svgs/next.svg'
import { toast } from 'react-toastify';
import platform from "platform";
import { useNavigate, useSearchParams } from 'react-router-dom';
import OtpInputModal from '../../components/core/SignInModal/OtpInputModal';
import { BASE_URL } from '../../utils/config';
import goBackSvg from '../../assets/images/svgs/goback.svg'

const SignUpForm = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [signIn, setSignIn] = useState(true);
    const [otpInput, setOtpInput] = useState(false);
    const [otpValue, setOtpValue] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [sessionDetails, setSessionDetails] = useState({})
    const [userData, setUserData] = useState({
        skinType: [],
        skinGoals: [],
        ig_profile: '',
        ig_followers: '',
        dob: '',
        age: '',
        gender: '',
        city: '',
        // userName: '',
    })

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const refId = searchParams.get('utm_source')
        const content = searchParams.get('utm_content')
        const authStatus = searchParams.get('auth')
        const sourceStat = searchParams.get('source')
        const screenWidth = window.screen.width;
        let deviceType = ''
        if (screenWidth >= 992) {
            deviceType = 'Desktop'
        } else if (screenWidth >= 768) {
            deviceType = 'Tablet'
        } else {
            deviceType = 'Mobile'
        }
        let isLocationEnabled = false
        if (navigator.geolocation) {
            isLocationEnabled = true
        }
        let deviceDetails = JSON.stringify({
            browser: window.navigator.userAgent,
            language: window.navigator.language,
            platform: window.navigator.platform,
            screenResolution: `${window.screen.width} x ${window.screen.height}`,
        })
        const sessionDetailsObj = {
            refId,
            content,
            auth: authStatus,
            source: sourceStat,
            deviceId: window.navigator.platform,
            deviceOs: platform.os.toString(),
            userAgent: window.navigator.userAgent,
            deviceType,
            isLocationEnabled,
            deviceDetails
        }
        localStorage.setItem("sessionDetails", JSON.stringify(sessionDetailsObj));
        setSessionDetails(sessionDetailsObj)
    }, [])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            setShow(true)
        }
    }, [])

    const handleSendOtp = (reqBody) => {
        const url = `${BASE_URL}api/user/sendOTP/v2`
        axios.post(url, reqBody)
            .then(response => {
                console.log('Response:', response.data)
                setOtpInput(true)
            })
            .catch(error => console.error('Error:', error));
    }

    const handleVerifyOtp = () => {
        const data = {
            ...sessionDetails,
            mobileNumber,
            otpValue,
            countryCode: "91",
            serviceType: "signin",
        }
        const url = `${BASE_URL}api/user/verifyOTP/v2`
        axios.post(url, data)
            .then(response => {
                console.log('Response:', response.data)
                if (response?.data?.success) {
                    // localStorage.setItem("user", JSON.stringify(response?.data));
                    setShow(false)
                    navigate('/dashboard')
                } else if (response?.data?.OTPValid == 0) {
                    toast.error('Invalid Otp')
                }
            })
            .catch(error => console.error('Error:', error));
    }

    const handleSignIn = () => {
        // const signIn = true;
        const url = signIn ? `${BASE_URL}api/user/influencer/signin` : `${BASE_URL}api/user/influencer/signup`
        const reqBody = signIn
            ? {
                ...sessionDetails,
                mobileNumber,
                countryCode: "91",
                serviceType: "signin",
            }
            : {
                ...sessionDetails,
                ...userData,
                serviceType: "signup",
                mobileNumber,
                countryCode: "91",
                userName: `${firstName} ${lastName}`
            }
        axios.post(url, reqBody)
            .then(response => {
                console.log('Response:', response.data)
                // localStorage.setItem("signinDetails", JSON.stringify(response?.data));
                if (signIn) {
                    if (response?.data?.newUser == 1) {
                        toast.warn('Account doesnot exist for this number, Sign up first')
                        setShow(false)
                    } else {
                        const data = {
                            ...response?.data,
                            ...reqBody
                        }
                        localStorage.setItem("user", JSON.stringify(data))
                        handleSendOtp(reqBody)
                        setOtpInput(true);
                        // navigate('/dashboard')
                    }
                } else {
                    const data = {
                        ...response?.data,
                        ...reqBody
                    }
                    localStorage.setItem("user", JSON.stringify(data))
                    handleSendOtp(reqBody)
                    setOtpInput(true);
                    // navigate('/dashboard')
                }
            })
            .catch(error => console.error('Error:', error));
    }

    const handleSelectConcern = (val) => {
        const selected = userData.skinGoals
        if (selected.length == 3) {
            if (selected.includes(val)) {
                const updated = selected.filter(x => x != val)
                setUserData({ ...userData, skinGoals: updated })
            } else {
                const toastTxt = `You can select only 3 goals Unselect any one to select new`
                toast.warn(toastTxt)
            }
        } else {
            if (selected.includes(val)) {
                const updated = selected.filter(x => x != val)
                setUserData({ ...userData, skinGoals: updated })
            } else {
                selected.push(val)
                setUserData({ ...userData, skinGoals: selected })
            }
        }
    }

    const handleSubmit = () => {
        let propertyToCheck = index == 1 ? { property: 'ig_profile', message: 'Instagram profile is required' }
            : index == 2 ? { property: 'ig_followers', message: 'Please select number of Instagram followers' }
                : index == 3 ? { property: 'age', message: 'Please select age' }
                    : index == 4 ? { property: 'gender', message: 'Please select Gender' }
                        : index == 5 ? { property: 'city', message: 'Please enter City' }
                            : index == 6 ? { property: 'dob', message: 'Date of Birth is required' }
                                : index == 7 ? { property: 'skinType', message: 'Please select skin type' }
                                    : index == 8 ? { property: 'skinGoals', message: 'Please select 3 skin goals' } : ''

        if (index < 8) {
            if (index == 0) {
                firstName?.trim() ? setIndex(index + 1) : toast.warn('Please enter Username')
            } else {
                if (index == 7) {
                    userData[propertyToCheck?.property]?.length > 0 ? setIndex(index + 1) : toast.warn(propertyToCheck?.message)
                }
                else userData[propertyToCheck?.property]?.trim() ? setIndex(index + 1) : toast.warn(propertyToCheck?.message)
            }
            // setIndex(index + 1)
        } else {
            if (userData[propertyToCheck?.property]?.length == 3) {
                setOtpInput(false)
                setSignIn(false)
                setShow(true)
            }
            else toast.warn(propertyToCheck?.message)
        }
    }

    return (
        <Container style={{ overflowX: 'hidden', overflow: 'hidden' }}>
            <Row
                className="justify-content-center"
                style={{ height: '100%' }}
            >
                {/* <Col md={4} lg={4} sm={12} xs={12}> */}
                {/* {index > 0 && <img
                        src={goBackSvg}
                        className='go-back'
                        onClick={() => setIndex(index - 1)}
                    />} */}
                {index == 0 ? <EnterName
                    firstName={firstName}
                    lastName={lastName}
                    onChangeFN={setFirstName}
                    onChangeLN={setLastName}
                />
                    : index == 1 ? <EnterIgProfile
                        val={userData?.ig_profile}
                        placeholder={'IG profile link'}
                        onChange={val => setUserData({ ...userData, ig_profile: val })}
                    />
                        : index == 2 ? <SelectFollowers
                            val={userData?.ig_followers}
                            setVal={(val) => setUserData({ ...userData, ig_followers: val })}
                        />
                            : index == 3 ? <SelectAgeGroup
                                val={userData?.age}
                                setVal={(val) => setUserData({ ...userData, age: val })}
                            />
                                : index == 4 ? <SelectGender
                                    val={userData?.gender}
                                    setVal={(val) => setUserData({ ...userData, gender: val })}
                                />
                                    : index == 5 ? <EnterCity
                                        val={userData?.city}
                                        onChange={val => setUserData({ ...userData, city: val })}
                                    />
                                        : index == 6 ? <EnterDOB
                                            val={userData?.dob}
                                            onChange={val => setUserData({ ...userData, dob: val })}
                                        />
                                            : index == 7 ? <SelectSkinType
                                                val={userData?.skinType}
                                                setVal={val => setUserData({ ...userData, skinType: [val] })}
                                            />
                                                : index == 8 ? <SelectConcern
                                                    val={userData?.skinGoals}
                                                    setVal={handleSelectConcern}
                                                />
                                                    : ''}
                {/* </Col> */}
                <Col md={4} lg={4} sm={12} xs={12} className='p0' style={{ position: 'absolute', bottom: 0 }}>
                    <div className='bottom-bar'>
                        {index > 0 && <div
                            style={{ display: 'flex', cursor: 'pointer' }}
                            onClick={() => setIndex(index - 1)}>
                            <img
                                src={Next}
                                style={{ height: 14, alignSelf: 'center', marginTop: 10, transform: "rotate(180deg)" }}
                            />
                            <p className='next' style={{ marginLeft: 8 }}>
                                Previous
                            </p>
                        </div>}
                        <div style={{ flex: 1, marginLeft: 24 }}>
                            <p className='percent'>{`${index * 10}%`}</p>
                            <div className='progress-bar'>
                                <div className='progress' style={{ width: `${index * 10}%` }} />
                            </div>
                        </div>

                        <div
                            style={{ display: 'flex', cursor: 'pointer' }}
                            onClick={handleSubmit}>
                            <p
                                className='next'
                            >
                                Next
                            </p>
                            <img
                                src={Next}
                                style={{ height: 14, marginLeft: 6, alignSelf: 'center', marginTop: 10 }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <SignInModal
                show={show}
                // show={true}
                close={() => setShow(false)}
                signIn={signIn}
                onChange={val => setMobileNumber(val)}
                onSubmit={handleSignIn}
                setSignIn={() => setSignIn(false)}
                otpInput={otpInput}
                otpValue={otpValue}
                setOtpValue={setOtpValue}
                onSubmitOtp={handleVerifyOtp}
            />
            {/* <OtpInputModal show={true} close={() => console.log('jnvjknkvnfknvkfj')} /> */}

        </Container>
    )
}

export default SignUpForm