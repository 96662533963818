import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import './SignInModal.css'
import Logo from '../../../assets/images/svgs/logo.svg'
// import OTPInput from 'react-otp-input';
import OtpInput from './OtpInput';

const OtpInputModal = ({ show, close }) => {
    return (
        <>
            <Modal
                show={show}
                onHide={close}
                aria-labelledby="contained-modal-title-vcenter br4"
                centered
            >
                <Modal.Body className='modal-container'>
                    <img src={Logo} className='img' />
                    <p className='title'>Login to your profile</p>
                    <OtpInput
                        // value={otpValue}
                        value={'1234'}
                        // onChange={setOtpValue}
                        onChange={() => console.log('mdlkmvkd')}
                        // OTPLength={4}
                        length={4}
                        onComplete={() => console.log('ayo')}
                        otpType="number"
                        disabled={false}
                        inputStyles={{ backgroundColor: '#2B3274', borderColor: '#ffffff66', color: '#fff', outline: 'none' }}
                    />
                    <Button className='signin-btn'
                    // onClick={onSubmit}
                    >
                        {/* {signIn ? 'Sign in' : 'Sign up'} */}
                        Sign in
                    </Button>
                    {/* <p
                        className='title'
                        style={{ marginTop: 16, cursor: 'pointer' }}
                    onClick={setSignIn}
                    >
                        {signIn && 'Dont have account? Create Account'}
                    </p> */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OtpInputModal