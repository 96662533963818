import React from 'react'
import CustomInput from '../../components/core/CustomInput/CustomInput'

const EnterName = ({ firstName, lastName, onChangeFN, onChangeLN }) => {
    return (
        <div>
            <p className='title'>Tell us your name</p>
            <p className='subtitle'>Enter full name</p>
            <CustomInput
                placeholder={'First Name'}
                val={firstName}
                onChange={e => onChangeFN(e.target.value)}
            />
            <CustomInput
                placeholder={'Last Name'}
                val={lastName}
                onChange={e => onChangeLN(e.target.value)}
            />
        </div>
    )
}

export default EnterName