import React from 'react'
import SkinProfileOptionCard from './SkinProfileOptionCard'

const options = ['0 - 1000', '1000 - 5000', '5000 - 10000', '10000+']

const SelectFollowers = ({ val, setVal }) => {
    return (
        <div>
            <p className='title'>
                How many followers do you have on Instagram?
            </p>
            <p className='subtitle'>
                Select one
            </p>
            {options?.map(option => <SkinProfileOptionCard
                label={option}
                val={option}
                handlePress={() => setVal(option)}
                isSelected={val == option}
            />)}
        </div>
    )
}

export default SelectFollowers