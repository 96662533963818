// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    border-radius: 0px 0px 12px 12px;
    background: #162B60;
    box-shadow: 0px 4px 77px 0px rgba(255, 255, 255, 0.25);
    padding: 10px 20px;
    margin-left: 16px;
    margin-right: 16px;
    position: absolute;
    top: 0;
    position: sticky;
    display: flex;
    justify-content: space-between;
}

.header-chip {
    display: flex;
    width: 97px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 100px;
    background: #3F4E99;
}

.header-chip p {
    color: #FFF;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 0;
}

.header-chip img {
    height: 20px;
    width: 20px;
}

.heading {
    color: #FFF;
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    align-self: flex-start;
    text-align: start;
    margin-bottom: 0;
}

@media screen and (max-width: 600px) {
    .header {
        margin-left: 0;
        margin-right: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/core/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,mBAAmB;IACnB,sDAAsD;IACtD,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,MAAM;IACN,gBAAgB;IAChB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,cAAc;IACd,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,cAAc;QACd,eAAe;IACnB;AACJ","sourcesContent":[".header {\n    border-radius: 0px 0px 12px 12px;\n    background: #162B60;\n    box-shadow: 0px 4px 77px 0px rgba(255, 255, 255, 0.25);\n    padding: 10px 20px;\n    margin-left: 16px;\n    margin-right: 16px;\n    position: absolute;\n    top: 0;\n    position: sticky;\n    display: flex;\n    justify-content: space-between;\n}\n\n.header-chip {\n    display: flex;\n    width: 97px;\n    padding: 10px;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    flex-shrink: 0;\n    border-radius: 100px;\n    background: #3F4E99;\n}\n\n.header-chip p {\n    color: #FFF;\n    font-family: Urbanist;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%;\n    margin-bottom: 0;\n}\n\n.header-chip img {\n    height: 20px;\n    width: 20px;\n}\n\n.heading {\n    color: #FFF;\n    font-family: Urbanist;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%;\n    align-self: flex-start;\n    text-align: start;\n    margin-bottom: 0;\n}\n\n@media screen and (max-width: 600px) {\n    .header {\n        margin-left: 0;\n        margin-right: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
