// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/pngs/bgPremium.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-btn {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.10);
    border-radius: 12px;
}

.img-bg {
    width: 320px;
    height: 268px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});    
}`, "",{"version":3,"sources":["webpack://./src/components/core/PricingPopup/PricingPopup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sFAAsF;IACtF,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yDAAkE;AACtE","sourcesContent":[".close-btn {\n    padding: 10px;\n    background-color: #fff;\n    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.10);\n    border-radius: 12px;\n}\n\n.img-bg {\n    width: 320px;\n    height: 268px;\n    background-image: url(\"../../../assets/images/pngs/bgPremium.png\");    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
