import React from 'react'

const SkinProfileOptionCard = ({ label, val, handlePress, isSelected }) => {
    // const selected = isSelected(val ? val : label)

    return (
        <div
            className='optionsCard'
            style={{ border: isSelected ? '2px solid #FCF36B' : '0.5px solid #fff' }}
            onClick={() => val ? handlePress(val) : handlePress(label)}
        >
            <div style={{
                height: 30,
                width: 30,
                borderRadius: 15,
                border: '2px solid #ffffff',
                justifyContent: 'center',
                display: 'flex'
            }}
            >
                {isSelected
                    && <div
                        style={{
                            height: 18,
                            width: 18,
                            borderRadius: 10,
                            backgroundColor: '#ffffff',
                            alignSelf: 'center'
                        }}
                    />}
            </div>
            <p className='optionLabel'>
                {label}
            </p>
        </div>
    )
}

export default SkinProfileOptionCard