import React from 'react'
import { Container, Row } from 'react-bootstrap'

const Home = () => {
    return (
        <Container>
            <Row className="justify-content-center" >
                {/* wadafaq */}
                {/* <input /> */}
            </Row>
        </Container>
    )
}

export default Home