import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './SignUp.css'
import Logo from '../../assets/images/svgs/logoBlueText.svg'
import India from '../../assets/images/svgs/india.svg'
import CustomInput from '../../components/core/CustomInput/CustomInput'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BASE_URL } from '../../utils/config'
import platform from "platform";
import axios from 'axios'
import { toast } from 'react-toastify'
import { generateToken } from '../../utils/generateToken'
import { Recommendations } from '@ub-infra/ub-recommends.git'
import bgimg from '../../assets/images/pngs/bg.png'

const SignUp = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [signIn, setSignIn] = useState(true);
    const [otpValue, setOtpValue] = useState('');
    const [show, setShow] = useState(false);
    const [otpInput, setOtpInput] = useState(false);
    // const [otpSent, setOtpSent] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [sessionDetails, setSessionDetails] = useState({})
    const [noCountDown, setNoCountdown] = useState(false)
    const [counter, setCounter] = useState(0);
    const [userData, setUserData] = useState({
        skinType: [],
        skinGoals: [],
        ig_profile: '',
        ig_followers: '',
        dob: '',
        age: '',
        gender: '',
        city: '',
        // userName: '',
    })

    useEffect(() => {
        const refId = searchParams.get('utm_source')
        const content = searchParams.get('utm_content')
        const authStatus = searchParams.get('auth')
        const sourceStat = searchParams.get('source')
        const screenWidth = window.screen.width;
        let deviceType = ''
        if (screenWidth >= 992) {
            deviceType = 'Desktop'
        } else if (screenWidth >= 768) {
            deviceType = 'Tablet'
        } else {
            deviceType = 'Mobile'
        }
        let isLocationEnabled = false
        if (navigator.geolocation) {
            isLocationEnabled = true
        }
        let deviceDetails = JSON.stringify({
            browser: window.navigator.userAgent,
            language: window.navigator.language,
            platform: window.navigator.platform,
            screenResolution: `${window.screen.width} x ${window.screen.height}`,
        })
        const sessionDetailsObj = {
            refId,
            content,
            auth: authStatus,
            source: sourceStat,
            deviceId: window.navigator.platform,
            deviceOs: platform.os.toString(),
            userAgent: window.navigator.userAgent,
            deviceType,
            isLocationEnabled,
            deviceDetails
        }
        localStorage.setItem("sessionDetails", JSON.stringify(sessionDetailsObj));
        setSessionDetails(sessionDetailsObj)
    }, [])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            setShow(true)
        }
    }, [])

    const handleSendOtp = () => {
        const reqBody = signIn
            ? {
                ...sessionDetails,
                mobileNumber,
                countryCode: "91",
                serviceType: "signin",
            }
            : {
                ...sessionDetails,
                ...userData,
                serviceType: "signup",
                mobileNumber,
                countryCode: "91",
                userName: `${firstName} ${lastName}`
            }
        setCounter(30)
        setNoCountdown(false)
        const url = `${BASE_URL}api/user/sendOTP/v2`
        axios.post(url, reqBody)
            .then(response => {
                console.log('Response:', response.data)
                if (response?.data?.newUser == 1 && signIn) {
                    toast.warn('Account doesnot exist for this number, Sign up first')
                    setShow(false)
                    setNoCountdown(true)
                    setCounter(0)
                } else {
                    setOtpInput(true)
                    countdown()
                }
            })
            .catch(error => {
                setCounter(0)
                console.error('Error:', error)
            });
    }

    const handleVerifyOtp = () => {
        const data = {
            ...sessionDetails,
            mobileNumber,
            otpValue,
            countryCode: "91",
            serviceType: signIn ? "signin" : "signup",
        }
        const url = `${BASE_URL}api/user/verifyOTP/v2`
        axios.post(url, data)
            .then(response => {
                console.log('Response:', response.data)
                if (response?.data?.success) {
                    if (signIn) {
                        // generateToken()
                        // setShow(false)
                        // navigate('/stats')
                        handleSignIn()
                    } else handleSignUp()
                } else if (response?.data?.OTPValid == 0) {
                    toast.error('Invalid Otp')
                }
            })
            .catch(error => console.error('Error:', error));
    }

    const handleSignIn = () => {
        const url = `${BASE_URL}api/user/influencer/signin`
        const reqBody = {
            ...sessionDetails,
            mobileNumber,
            countryCode: "91",
            serviceType: "signin",
        }
        axios.post(url, reqBody)
            .then(response => {
                console.log('Response:', response.data)
                // if (response?.data?.newUser == 1) {
                //     toast.warn('Account doesnot exist for this number, Sign up first')
                //     setShow(false)
                // } else {
                const data = {
                    ...response?.data,
                    ...reqBody
                }
                localStorage.setItem("user", JSON.stringify(data))
                // handleSendOtp()
                // setOtpInput(true);
                generateToken()
                setShow(false)
                navigate('/stats')
                // navigate('/dashboard')
                // }
            })
            .catch(error => {
                console.error('Error:', error?.response?.status)
                if (error?.response?.status == 403) {
                    navigate('/blocked')
                }
            });
    }

    const handleSignUp = () => {
        // const signIn = true;
        if (firstName?.trim()?.length < 3) {
            toast.warn('First name is required, atleast 4 characters')
            return
        }
        const url = `${BASE_URL}api/user/influencer/signup`
        const reqBody = {
            ...sessionDetails,
            ...userData,
            serviceType: "signup",
            mobileNumber,
            countryCode: "91",
            userName: `${firstName} ${lastName}`
        }
        axios.post(url, reqBody)
            .then(response => {
                console.log('Response:', response.data)
                const data = {
                    ...response?.data,
                    ...reqBody,
                    mobileNumber
                }
                localStorage.setItem("user", JSON.stringify(data))
                generateToken()
                setShow(false)
                navigate('/stats')
                // handleSendOtp(reqBody)
                // setOtpInput(true);
                // navigate('/dashboard')
            })
            .catch(error => {
                console.error('Error:', error?.response?.status)
                if (error?.response?.status == 403) {
                    navigate('/blocked')
                }
            });
    }

    const requestSignIn = () => {
        if (signIn) {
            handleSignIn()
        } else {
            handleSendOtp()
            setOtpInput(true);
        }
    }

    const countdown = () => {
        if (!noCountDown) {
            if (counter > 0) {
                setTimeout(() => {
                    setCounter(counter - 1)
                }, 1000)
            }
        } else {
            setCounter(0)
        }
    }

    useEffect(() => {
        countdown()
    }, [counter])

    return (
        <div>
            <img src={bgimg} style={{width: '100vw', height: '100vh'}}/>
            <Recommendations show={true} onClose={() => setShow(false)} />
        </div>
        // <Container>
        //     <Row
        //         className="justify-content-center align-content-center p-2"
        //         style={{ height: '100vh', marginTop: 24, display: 'flex' }}
        //     >
        //         <div className='form-conatiner'>
        //             <img src={Logo} />
        //             <p className='title'>Login to your profile</p>
        //             <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: 20, alignItems: 'flex-start' }}>
        //                 {!signIn && <>
        //                     <p className='label'>First Name</p>
        //                     <CustomInput val={firstName} placeholder={'Enter First name'} onChange={e => setFirstName(e.target.value)} />

        //                     <p className='label'>Last Name</p>
        //                     <CustomInput val={lastName} placeholder={'Enter Last name'} onChange={e => setLastName(e.target.value)} />
        //                 </>}
        //                 <p className='label'>Enter Phone No.</p>
        //                 <div className='input-container' style={{ padding: 6, marginTop: 0 }}>
        //                     <img src={India} />
        //                     <p className='country-code'>+91 -</p>
        //                     <input
        //                         className='mobile-input'
        //                         maxLength={10}
        //                         value={mobileNumber}
        //                         placeholder='Phone number'
        //                         onChange={e => setMobileNumber(e.target.value)}
        //                     />
        //                 </div>

        //                 <Row style={{ width: '100%' }}>
        //                     <Col md={8} xs={8} sm={8} style={{ paddingRight: 0 }}>
        //                         <CustomInput
        //                             val={otpValue}
        //                             onChange={e => setOtpValue(e.target.value)}
        //                             placeholder={'Enter Otp'}
        //                             style={{ backgroundColor: '#FFF' }}
        //                         />
        //                     </Col>
        //                     <Col md={4} xs={4} sm={4} style={{ paddingLeft: 6, paddingRight: 0 }} >
        //                         <Button
        //                             style={{ backgroundColor: '#4254EA', border: 0, borderRadius: 12, width: '100%', opacity: counter > 0 ? 0.6 : 1 }}
        //                             onClick={() => counter <= 0 ? mobileNumber?.length == 10
        //                                 ? handleSendOtp() : toast.warn('Please enter valid mobile number')
        //                                 : null}
        //                         >
        //                             Get Otp
        //                         </Button>
        //                     </Col>
        //                 </Row>
        //                 <p className='resend'>Resend in<span className='counter'>00:{`${counter < 10 ? '0' : ''}${counter}`}</span></p>

        //                 <Button
        //                     className='signin-btn'
        //                     // onClick={otpInput ? handleVerifyOtp : handleSignIn}
        //                     onClick={otpInput ? otpValue?.length == 4 ? handleVerifyOtp : () => toast.warn('Enter valid otp') : () => toast.warn('Get Otp')}
        //                     style={{ opacity: otpInput && otpValue?.length == 4 ? 1 : 0.8 }}
        //                 >
        //                     {signIn ? 'Sign in' : 'Sign up'}
        //                 </Button>
        //                 <div style={{ backgroundColor: '#E4E4E7', height: 1, width: '100%', marginTop: 10 }} />
        //                 <p className='have-acc' onClick={() => setSignIn(!signIn)}>
        //                     {signIn ? 'Don’t have an Account?' : 'Already have an Account?'}
        //                     <span className='log-in'>{signIn ? 'Sign-up' : 'Log In'}</span>
        //                 </p>
        //             </div>
        //         </div>
        //     </Row>
        //     <Recommendations show={true} onClose={() => setShow(false)} />
        // </Container>
    )
}

export default SignUp