import React from 'react'
import SkinProfileOptionCard from './SkinProfileOptionCard'

const options = [
    { label: 'Less than 20 years', value: '< 20' },
    { label: '20-25 years', value: '20 - 25' },
    { label: '26-35 years', value: '26 - 35' },
    { label: '36-50 years', value: '36 - 50' },
]

const SelectAgeGroup = ({ val, setVal }) => {
    return (
        <div>
            <p className='title'>
                What is your age?
            </p>
            <p className='subtitle'>
                Select one
            </p>
            {options?.map(({ label, value }) => <SkinProfileOptionCard
                label={label}
                val={value}
                handlePress={() => setVal(value)}
                isSelected={val == value}
            />)}
        </div>
    )
}

export default SelectAgeGroup